import React, { useState } from "react";
import {
  Layout,
  Col,
  Row,
  Button,
  Form,
  Input,
  Checkbox,
  Image,
  Radio,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import Logo from "../../assets/logo-header.png";
import Dogimg3 from "../../assets/dogimg3.png";
import swal from "sweetalert";
import { PiUserCirclePlusFill } from "react-icons/pi";
import { ImUserPlus } from "react-icons/im";
import ConditionalBox from "../../components/ConditionalBox";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";

function WelcomeScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token, navigate]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [value, setValue] = useState(1);
  const handleNext = () => {
    if (value < 5) {
      setValue(value + 1);
    }
  };
  const handleBack = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };
  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="auth-banner">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xl={11} lg={16} md={22} sm={23} xs={23}>
            <div className="welcome-bg" style={{ textAlign: "center" }}>
              <div class="arrow-box">
                <FaArrowLeftLong className="arrow" onClick={handleBack} />
              </div>
              <Image
                preview={false}
                alt="Failed to load image"
                width={130}
                height={60}
                src={Logo}
              />
              <ConditionalBox value={value} setValue={setValue} />
              <div className="web-step">
                <h6 className="skip">Skip</h6>
                <div className="stepbbars">
                  <span className={"yellow-bar"}></span>
                  <span className={`${value>=2 ? 'yellow-bar':''}`}></span>
                  <span className={`${value>=3 ? 'yellow-bar':''}`}></span>
                  <span className={`${value>=4 ? 'yellow-bar':''}`}></span>
                  <span className={`${value>=5 ? 'yellow-bar':''}`}></span>
                  {/* <span className={`${value>=6 ? 'yellow-bar':''}`}></span>
                  <span className={`${value>=7 ? 'yellow-bar':''}`}></span>
                  <span className={`${value>=8 ? 'yellow-bar':''}`}></span>
                  <span className={`${value>=9 ? 'yellow-bar':''}`}></span> */}
                </div>
                <h6 className="next" onClick={handleNext}>
                  Next <MdKeyboardArrowRight />
                </h6>
              </div>
            </div>
          </Col>
          {/* <Button onClick={handleNext}>Next</Button> */}
        </Row>
      </div>
    </Layout>
  );
}

export default WelcomeScreen;
