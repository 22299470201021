import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Card,
  Checkbox,
  Rate,
  Typography,
  Button,
  Input,
  Divider,
  Form,
  Select,
  DatePicker,
  TimePicker,
  Space,
  Radio,
  Image,
} from "antd";
import { useNavigate, useParams } from "react-router";
import {
  FaArrowLeftLong,
  FaLocationDot,
  FaParachuteBox,
} from "react-icons/fa6";
import { serviceData, reviews } from "../../../components/Data/data";
import { MdSunny } from "react-icons/md";
import Slider from "react-slick";
import Modals from "../../../components/Modals";
import selectpet from "../../../assets/select-pet.png";
import selectpet2 from "../../../assets/select-pet2.png";
import selectpet3 from "../../../assets/select-pet3.png";
// const { Meta } = Card;
const { Title } = Typography;

const JumpDetails = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();
  const [service, setService] = useState(
    serviceData.find((item) => item.id == id)
  );
  const sliderSettings = {
    arrows: false,
    // other settings...
  };
  const sliderSettings2 = {
    // arrows: false,
    // style: {
    //   margin: "20px",
    // },
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const PickerWithType = ({ type, onChange }) => {
    if (type === "time") return <TimePicker onChange={onChange} />;
    if (type === "date") return <DatePicker onChange={onChange} />;
    return <DatePicker picker={type} onChange={onChange} />;
  };
  const { Option } = Select;
  const [type, setType] = useState("time");
  const { TextArea } = Input;

  const [show1, setShow1] = useState(false);
  const handleShow1 = () => {
    setShow1(true);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div class="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Services Details</h3>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={23}>
              <Card className="details-card">
                <Row align="" gutter={16}>
                  <Col lg={10}>
                    <div
                      className="one-product"
                      style={{ paddingRight: "30px" }}
                    >
                      <div className="search-img">
                        <div>
                          <span>{service.pic}</span>
                        </div>
                        <div className="rating-box">
                          <Rate
                            allowHalf
                            defaultValue={service.rating}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={14}>
                    <div className="room-details">
                      <div>
                        <h4 className="text-26">{service.title}</h4>
                        <p className="web-p">
                          <FaLocationDot />
                          {service.loctaion}
                        </p>
                        <h6 className="booking-card-price">${service.price}</h6>
                        <p className="web-p">{service.additionalDetails}</p>
                      </div>
                    </div>
                    <Divider dashed />
                    <div className="search-img-box-right">
                      <Form
                        layout="vertical"
                        name="basic"
                        labelCol={{
                          span: 8,
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        className="dates-inputs"
                      >
                        <Row
                          style={{ width: "100%", justifyContent: "center" }}
                          gutter={[16, 16]}
                        >
                          <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                              label="Choose a Day"
                              name="SelectDate"
                              labelCol={{
                                span: 24,
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Select Date!",
                                },
                              ]}
                            >
                              <Space direction="vertical">
                                <DatePicker
                                  size="large"
                                  onChange={onChange}
                                  className="web-input"
                                  style={{
                                    width: "100%",
                                    paddingRight: "10px",
                                  }}
                                />
                              </Space>
                            </Form.Item>
                          </Col>
                          <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                              label="From"
                              name="SelectDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Date!",
                                },
                              ]}
                            >
                              <Space direction="vertical">
                                <PickerWithType
                                  type={type}
                                  onChange={(value) => console.log(value)}
                                  className="web-input"
                                  style={{
                                    width: "100%",
                                    paddingRight: "10px",
                                  }}
                                />
                              </Space>
                            </Form.Item>
                          </Col>
                          <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                              label="To"
                              name="SelectDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Date!",
                                },
                              ]}
                            >
                              <Space direction="vertical">
                                <PickerWithType
                                  type={type}
                                  onChange={(value) => console.log(value)}
                                  className="web-input"
                                  style={{
                                    width: "100%",
                                    paddingRight: "10px",
                                  }}
                                />
                              </Space>
                            </Form.Item>
                          </Col>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                              label="Additional Comment (Optional)"
                              name="SelectDate"
                              labelCol={{
                                span: 24,
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Select Date!",
                                },
                              ]}
                            >
                              <TextArea
                                rows={4}
                                placeholder="Your Message Here...."
                                maxLength={6}
                                className="web-textarea "
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>

                    <div>
                      <Button
                        type=""
                        className="web-btn"
                        onClick={handleShow1}
                        // onClick={() => navigate("/checkout", {state : {id : service.id}})}
                      >
                        Book Now
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row justify="center">
            <Col lg={23}>
              <div className="details-card">
                <Title level={3} className="text-24-bold pb-5">
                  Rating & Reviews
                </Title>

                <Row justify={"space-between"}>
                  <Col lg={12} xs={24}>
                    <Title level={2} className="rating-5">
                      5.0<span>/500</span>
                    </Title>
                    <div className="pb-3">
                      <Rate allowHalf defaultValue={5} disabled />
                    </div>
                    <a href="#_" className="blue-link">
                      100 Reviews
                    </a>
                  </Col>

                  <Col lg={12} xs={24}>
                    {/* Ratings with bars */}
                    {/* Replace 'bar-5' with the actual CSS class for the bar width based on your data */}
                    {Array.from({ length: 4 }, (_, i) => (
                      <div
                        key={i}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="side">
                          <div className="star-18">
                            <Rate allowHalf defaultValue={i + 1} disabled />
                          </div>
                        </div>
                        <div className="middle">
                          <div className={`bar-container bar-${i + 2}`}></div>
                        </div>
                        <div className="side right">
                          <div>150</div>
                        </div>
                      </div>
                    ))}
                  </Col>
                </Row>

                {/* Review boxes */}
                {reviews.map((reviews, index) => (
                  <div key={index} className="review-box">
                    <div className="for-media-object">
                      <div className="flex-shrink-0">
                        <span>{reviews.reviewsProfile}</span>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div className="star-18">
                          <Rate
                            allowHalf
                            defaultValue={reviews.rating}
                            disabled
                          />
                          {/* <Rate allowHalf defaultValue={5} disabled /> */}
                        </div>
                        <h6>{reviews.reviewName}</h6>
                        <p>{reviews.reviewDiscretion}</p>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Write a Review */}
                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="form-label">Write a Review</h6>
                    <div className="rate">
                      <Rate allowHalf />
                    </div>
                  </div>
                  <Input.TextArea
                    className="form-control web-textarea"
                    rows={5}
                  />
                </div>

                <div className="text-center">
                  <Button type="primary" className="btn web-btn">
                    Post Review
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Modals
            centered
            open={show1}
            handleOk={() => setShow1(false)}
            handleCancel={() => setShow1(false)}
            title="Select Pet"
            text={
              <div className="">
                <Radio.Group
                  className=" select-pet"
                  onChange={onChange}
                  defaultValue=""
                >
                  <Radio.Button className="for-w" value="Cat">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={selectpet}
                    />
                  </Radio.Button>
                  <Radio.Button className="for-w" value="Dog">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={selectpet2}
                    />
                  </Radio.Button>
                </Radio.Group>
              </div>
            }
            footer={[
              <Button
                key="submit"
                type=""
                className="web-btn"
               onClick={() => navigate("/checkout", {state : {id : service.id}})}
                style={{ textAlign: "center" }}
              >
                Proceed To Pay
              </Button>,
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default JumpDetails;
